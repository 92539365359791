import { useCallback, useEffect, useRef, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// types
import { IProductTableFilters, IProductTableFilterValue } from 'src/types/product';
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { IStoreType } from 'src/types/store';
import { RHFSelect } from 'src/components/hook-form';
import { Box, Button } from '@mui/material';
import useHasRoles from 'src/hooks/use-has-roles';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { RootState, useSelector } from 'src/redux/store';
import ChannelQuickInstanceForm from '../channel/channel-quick-instance-form';

// ----------------------------------------------------------------------

type Props = {
  filters: any;
  onFilters: (name: string, value: IProductTableFilterValue) => void;
  storeOptions: any[];
};

export default function ChannelTableToolbar({
  filters,
  onFilters,
  storeOptions
}: Props) {
  const popover = usePopover();
  const [channels, setChannels] = useState<any[]>([]);
  const [selectedChannel, setselectedChannel] = useState<any>({ name: '', base_path: '', username_type: '' });
  const isAdmin = useHasRoles(['ROLE_ADMIN']);
  const stateStore: any = useSelector((state: RootState) => state.store);
  const [billingPlans, setBillingPlans] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [storeName, setStoreName] = useState(filters.name);
  const timeoutIdRef = useRef<any>(undefined);
  




  useEffect(() => {
    if (stateStore?.id && filters.store !== stateStore.id) {

      onFilters('store', stateStore.id);
    }
   else if (!filters.store && storeOptions.length > 0 && !isAdmin) {
      onFilters('store',storeOptions[0].id);
    }
  }, [filters.store, onFilters, storeOptions, stateStore, isAdmin]);

  useEffect(() => {
    getChannelList();
  }, []);
  useEffect(() => {
    if (channels && channels.length === 1) {
      setselectedChannel(channels[0]);
    }
  }, [channels]);
  const getChannelList = () => {
    axios
      .get(API_ENDPOINTS.channel.availables)
      .then(({ data }) => {
        setChannels(data);
      })
      .catch((error) => {

      });
  };
  const handleFilterStore = useCallback(
    (event: any) => {
      onFilters(
        'store',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );
  const changeChannel = (event: any) => {

    const NAME = event.target.value;
    const CHANNEL = channels.find((item) => item.name === NAME);
    setselectedChannel(CHANNEL)

    onFilters('channelName', CHANNEL?.name);

  }

  const renderFlag = (country: string) => {
    switch (country) {
      case 'AR':
        return <Iconify icon="twemoji:flag-argentina" />;
      case 'MX':
        return <Iconify icon="twemoji:flag-mexico" />;
      case 'BR':
        return <Iconify icon="twemoji:flag-brazil" />;
      case 'CO':
      case 'COL':
        return <Iconify icon="twemoji:flag-colombia" />;
      case 'CL':
        return <Iconify icon="twemoji:flag-chile" />;
      default:
        return <Box>{country}</Box>;
    }
  }

  const handleFilterBillingPlan = useCallback(
    (event: any) => {
      onFilters(
        'billingPlan',
         event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterCountry = useCallback(
    (event: any) => {
      onFilters(
        'country',
         event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      setStoreName(value);
      clearTimeout(timeoutIdRef.current);
      if (value.length >= 3){
        timeoutIdRef.current = setTimeout(() => {
          // Aquí puedes realizar tu llamada a la API con el valor
          onFilters('name', value);
        }, 500);
      }
      if (value.length === 0){
        onFilters('name', event.target.value);      
      }
    },
    [onFilters]
  );

useEffect(() => {
    axios
    .get(API_ENDPOINTS.store.countryList)
    .then(({ data }) => {
      const countryList = data.map((row: any) => {
        let countryName = '';
        switch (row.country) {
          case 'AR':
            countryName = 'Argentina';
            break;
          case 'MX':
            countryName = 'México';
            break;
          case 'BR':
            countryName = 'Brasil';
            break;
          case 'CO':
          case 'COL':
            countryName = 'Colombia';
            break;
          case 'CL':
            countryName = 'Chile';
            break;
          default:
            countryName = row.country;
        }
        return { code: row.country, name: countryName };
      });
      setCountries(countryList);
    })
    .catch((error) => {
      // Manejar error
    });
}, [])

  useEffect(() => {

      axios
      .get(API_ENDPOINTS.store.planList)
      .then(({ data }) => {
        setBillingPlans(data);
      })
      .catch((error) => {
        // Handle error
      });
    
  }, [])

  const quickEdit = useBoolean();


  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >

          <TextField            
            value={storeName}
            onChange={handleFilterName}
            placeholder="Buscar merchant..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
      
       <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 170 },
            }}
          >
            <InputLabel>País</InputLabel>
            <Select
              value={filters.country || ''}
              onChange={(event) => onFilters('country', event.target.value)}
              input={<OutlinedInput label="País" />}
              renderValue={(selected) => {
                const selectedCountry = countries.find((country) => country.code === selected);
                return selectedCountry ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {renderFlag(selectedCountry.code)}
                    <Box sx={{ ml: 0.5 }}>{selectedCountry.name}</Box>
                  </Box>
                ) : '';
              }}
              sx={{ textTransform: 'capitalize' }}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {countries && countries.map((country) => (
                <MenuItem key={country.name} value={country.code}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.country === country}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {renderFlag(country.code)}
                    <Box sx={{ ml: 0.5 }}>{country.name}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        <Stack direction="row" spacing={2}>
        <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 170 },
            }}
          >
            <InputLabel>Plan de pago</InputLabel>
                <Select            
                  value={filters.billingPlan || ''}
                  onChange={handleFilterBillingPlan}
                  input={<OutlinedInput label="Plan de pago" />}
                  renderValue={(selected) =>                
                    billingPlans.find((item: any) => item?.plan_name === selected)?.plan_name              
                  }
                  sx={{ textTransform: 'capitalize' }}
                >
                  <MenuItem key='default-billing-plan' value=''>
                        Todos
                      </MenuItem>
                  {billingPlans.length>0 &&
                    billingPlans.map((option: any) => (
                      <MenuItem key={option.plan_name} value={option.plan_name}>
                        <Checkbox
                          disableRipple
                          size="small"
                          checked={filters.billingPlan === option.plan_name}
                        />
                        {option.plan_name}
                      </MenuItem>
                    ))}
                </Select>
        </FormControl>
        </Stack> 
        <Stack direction="row" spacing={2}>
          
          <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 180 },
            }}
          >
            <InputLabel>Modo de Operación</InputLabel>
            <Select
              value={filters.operationMode || ''}
              onChange={(event) => onFilters('operationMode', event.target.value)}
              input={<OutlinedInput label="Modo de Operación" />}
              renderValue={(selected) => selected}
              sx={{ textTransform: 'capitalize' }}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value="Automático">
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.operationMode === 'Automático'}
                />
                Automático
              </MenuItem>
              <MenuItem value="Manual">
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.operationMode === 'Manual'}
                />
                Manual
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 180 },
            }}
          >
            <InputLabel>Canales</InputLabel>
            <Select
              value={selectedChannel ? selectedChannel.name : ''} // Cambiar selectedChannel?.name por selectedChannel ? selectedChannel.name : ''
              onChange={changeChannel}
              input={<OutlinedInput label="Canales" />}
              renderValue={(selected) => channels.find((item) => item.name === selected).name}
              sx={{ textTransform: 'capitalize' }}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {channels && channels.map((option) => (               
                <MenuItem key={option.name} value={option.name}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={(selectedChannel && selectedChannel.name === option.name)}
                  />
                  {option.name}
                </MenuItem>                
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 180 },
            }}
          >
            <InputLabel>Estado de WhatsApp</InputLabel>
            <Select
              value={filters.status || ''}
              onChange={(event) => onFilters('status', event.target.value)}
              input={<OutlinedInput label="Estado" />}
              renderValue={(selected) => selected}
              sx={{ textTransform: 'capitalize' }}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value="Conectado">
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.status === 'Conectado'}
                />
                Conectado
              </MenuItem>
              <MenuItem value="Desconectado">
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.status === 'Desconectado'}
                />
                Desconectado
              </MenuItem>
            </Select>
          </FormControl>

         
         
        </Stack>
      </Stack>
      <ChannelQuickInstanceForm channel={selectedChannel?.name} open={quickEdit.value} usernameType={selectedChannel?.username_type} onClose={quickEdit.onFalse} basePath={selectedChannel?.base_path} />

    </>
  );
}
