import { useRef, useState, useEffect, useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
// routes
import { useRouter } from 'src/routes/hook';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import i18n from '@emoji-mart/data/i18n/es.json'
import { Box, MenuItem } from '@mui/material';
import { AudioPlayer } from 'react-audio-play';
import Typography from 'src/theme/overrides/components/typography';

type Props = {
  recipients: any;
  onSendCompose: any;
  onSendMessage: any;
  currentConversationId: any;
  onShowImagePreview: any;
  onSendAudio: any;
  customer?: any;
};

export default function ConversationMessageInput({
  recipients,
  onSendCompose,
  onSendMessage,
  onShowImagePreview,
  onSendAudio,
  currentConversationId,
  customer

}: Props) {
  const router = useRouter();
  const popover = usePopover();

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [iconAudio, setIconAudio] = useState('ic:outline-mic');
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);

  const isMobile = width <= 768;


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      readFile(event.target.files[0]);
    }
  };


  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);


  const readFile = (file: File) => {

    const reader = new FileReader();
    reader.onload = async (event: any) => {
      try {
        if (event.target) {

          setImgUrl(event.target.result as string);
          onShowImagePreview(event.target.result as string);



        }

      } catch (error) {
        console.error('Error in FileReader onload:', error);
      }
    }
    reader.readAsDataURL(file);
  }


  const sendAudio = () => {
    if (audioChunks.length > 0) {
      onSendAudio(new Blob(audioChunks, { type: 'audio/wav' }))
      setAudioChunks([])
      setMediaRecorder(null);
    }
  }


  const deleteAudio = () => {
    setMediaRecorder(null);
    setAudioChunks([])
  }


  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const recorder = new MediaRecorder(stream);
        recorder.ondataavailable = e => {
          setAudioChunks(prev => [...prev, e.data]);
        };
        recorder.start();
        setMediaRecorder(recorder);
      })
      .catch(error => console.error('Error al acceder al micrófono: ', error));
  };

  const stopRecording = () => {
    mediaRecorder?.stop();
  };

  const handleRecordClick = () => {
    if (!mediaRecorder) {
      setIconAudio("material-symbols:pause-circle-outline")
      startRecording();
    } else {
      setIconAudio("ic:outline-mic")
      stopRecording();
    }
  };



  const fileRef = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState('');

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const onEmojiClick = (event: any) => {
    setMessage((prevInput) => prevInput + event.native);
    setShowEmojiPicker(false);
  };


  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  }, []);

  const handleSend = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        if (message) {
          if (currentConversationId) {
            onSendMessage(message);
          } else {
            onSendCompose(message);
          }
        }
        setMessage('');
      }
    },
    [currentConversationId, message, onSendCompose, onSendMessage]
  );
  const sendMessage = () => {
    if (message) {
      if (currentConversationId) {
        onSendMessage(message);
      } else {
        onSendCompose(message);
      }
    }
    setMessage('');
  }



  return (
    <>

      {!mediaRecorder && (
        <>
          {showEmojiPicker && <Picker previewPosition="none" perLine={isMobile ? 9 : 22} data={data} onEmojiSelect={onEmojiClick} i18n={i18n} />}


          <Stack 
          
            direction="row"
           sx={{
              backgroundColor: '#F0F2F5',
              padding: '8px',
              maxWidth: '100%',
              position: { xs: 'fixed', sm: 'static' },
              bottom: { xs: 0, sm: 'auto' },                            
              width: { xs: '100%', sm: 'auto' }              
            }}           

          >
            {/* <IconButton onClick={() => setShowEmojiPicker((val) => !val)}>
              {!showEmojiPicker ?
                <Iconify icon="mdi:emoticon-outline" width={32} />
                :
                <Iconify icon="material-symbols:close" width={32} />
              }
            </IconButton> */}



            {/* <IconButton color='default' onClick={popover.onOpen}>
              {!popover.open ?
                <Iconify icon="ic:baseline-plus" width={32} />
                :
                <Iconify icon="material-symbols:close" width={32} />
              }

            </IconButton> */}
            {/* <CustomPopover
              open={popover.open}
              onClose={popover.onClose}
              arrow="bottom-center"
              sx={{ width: 140 }}
            >
              <MenuItem
                onClick={() => {
                  const input = document.getElementById('selectImage');
                  if (input) {
                    input.onchange = function (event: any) {
                      readFile(event.target.files[0]);
                    };
                    input?.click();
                  }
                  popover.onClose();
                }}
              >
                <Iconify color="#007BFC" width={24} icon="mdi:images" />
                Fotos
              </MenuItem>

            </CustomPopover> */}
            

            {/* <InputBase
              inputRef={inputRef}
              value={message}
              onKeyUp={handleSend}
              onChange={handleChange}
              placeholder="Escribe un mensaje"
              disabled={!recipients.length && !currentConversationId && message.length > 0}
              endAdornment={
                <Stack direction="row" sx={{ flexShrink: 0 }}>
                  <IconButton onClick={sendMessage}>
                    <Iconify width={32} icon="ic:baseline-send" />
                  </IconButton>
  
             
                </Stack>
              }
              sx={{
                px: 1,
                height: 46,
                borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
                backgroundColor: "white",
                borderRadius: '8px',
                '& .MuiInputBase-input': {
                  paddingLeft: '8px'
                },
                flexGrow: 1,                
              }}
            /> */}
            <Box
              sx={{ display: 'none' }}
            >
              <input type="file" accept="*.*" required id="selectFile" />
              <input type="file" accept="image/jpeg, image/png" required id="selectImage" />

            </Box>
          </Stack>
        </>

      )}


      {mediaRecorder && audioChunks.length === 0 && (
        <Stack direction="row" spacing={1} justifyContent='flex-end' sx={{
          backgroundColor: '#F0F2F5',
          maxWidth: '100%',
        }}>
          <InputBase
            endAdornment={
              <Stack direction="row" sx={{ flexShrink: 0 }}>
                {message ? (
                  <IconButton onClick={sendMessage}>
                    <Iconify width={32} icon="mingcute:send-fill" />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleRecordClick}>
                    <Iconify width={32} icon={iconAudio} />
                  </IconButton>
                )}
              </Stack>
            }
            sx={{
              px: 1,
              height: 56,
              flexShrink: 0,
              borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          />
        </Stack>
      )}



      {audioChunks.length > 0 && (

        <Stack direction="row" spacing={1} justifyContent='flex-end' sx={{
          backgroundColor: '#F0F2F5',
          maxWidth: '100%',
        }}>
          <IconButton onClick={deleteAudio}>
            <Iconify icon="ic:baseline-delete" width={32} />
          </IconButton>


          <AudioPlayer preload="auto" width="40%"
            color="#135D66"
            sliderColor="#00A884"
            src={URL.createObjectURL(new Blob(audioChunks))} />


        
          <IconButton onClick={sendAudio}>
            <Iconify icon="mdi:send-circle" width={32} sx={{ color: '#00A884' }} />
          </IconButton>

        </Stack>

      )}




    </>
  );
}
