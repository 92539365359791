import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    const logo = (
      <Box
        component="img"
        src="/logo/logo_tiendanube.png"
        sx={{ maxWidth: '180', height: 'auto', marginRight: '30px', cursor: 'pointer', ...sx }}
      />
    );


    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/dashboard/instance" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
