import { useState, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import Label from 'src/components/label';
import { showSpinner } from 'src/redux/slices/spinner';
import { useDispatch } from 'react-redux';
import { useLocales } from 'src/locales';
import { Mixpanel } from 'src/Mixpanel';
import Switch from '@mui/material/Switch';
import ChannelQuickEditInstanceForm from './channel-edit-quick-instance-form';

// ----------------------------------------------------------------------

type Props = {
  // row: IOrderItem;
  row: any;
  selected: boolean;
  onViewRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function CategoryTableRow({
  row,
  selected,
  onViewRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { id, channelName, store, username, basePath, state, actualStatus,storeOperationMode, country } = row;
  const { enqueueSnackbar } = useSnackbar();

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [active, setActive] = useState(state?.name === 'Active');
  const [stateName, setStateName] = useState(state?.name);

  const handleOpenEditModal = () => {
    setEditModalOpen(true);
  };
;

  // Función para cerrar el modal
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const confirm = useBoolean();

  const popover = usePopover();

  

  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useLocales();

  const handleChange = (event: any) => {
    if (event.target.checked === true) {
      setActive(true);
      axios
        .put(API_ENDPOINTS.channel.active(id))
        .then(({ data }) => {
          enqueueSnackbar('Activado con éxito');
          Mixpanel.track("User Activates Bot", {store_id: store?.id, store_name: store?.name, channel: channelName, username});
          setStateName('Active')
          router.push(paths.dashboard.instance.root);
        })
        .catch((error) => {
          setActive(false);          
          enqueueSnackbar('Se ha producido un error', {variant: 'error'});

        });
    } else {
      setActive(false);
      axios
        .put(API_ENDPOINTS.channel.pause(id))
        .then(({ data }) => {
          enqueueSnackbar('Pausado con éxito');  
          Mixpanel.track("User Pauses Bot", {store_id: store?.id, store_name: store?.name, channel: channelName, username});
          setStateName('Paused')    
          router.push(paths.dashboard.instance.root);
        })
        .catch((error) => {
          setActive(true);
          enqueueSnackbar('Se ha producido un error', {variant: 'error'});

        });
    }
  };
  const renderFlag = (country: string) => {
    switch (country) {
      case 'AR':
        return <Iconify icon="twemoji:flag-argentina" />;
      case 'MX':
        return <Iconify icon="twemoji:flag-mexico" />;
      case 'BR':
        return <Iconify icon="twemoji:flag-brazil" />;
      case 'CO':
        return <Iconify icon="twemoji:flag-colombia" />;
      case 'CL':
        return <Iconify icon="twemoji:flag-chile" />;
      default:
        return <Box>{country}</Box>;
    }
  }
  const renderPrimary = (
    <TableRow hover selected={selected} key={`i-${id}`}>
      <TableCell>
        <Box>{store?.name}</Box>
      </TableCell>
      <TableCell>
        <Box>
         {renderFlag(country)}
        </Box>
      </TableCell>
      <TableCell>
        <Box>{username}</Box>
      </TableCell>
      <TableCell>
        <Box>{channelName}</Box>
      </TableCell>
      <TableCell>
        <Label
          variant="soft"
          color={
            (storeOperationMode.name === 'AutoPilot' && 'success') ||
            (storeOperationMode.name === 'Copilot' && 'warning') ||
            (storeOperationMode.name === 'Manual' && 'error') ||
            'default'
          }
        >
          {t(`${storeOperationMode.name}`)}
        </Label>
      </TableCell>
      <TableCell>
        <Label
          variant="soft"
          color={
            (actualStatus?.name === 'Connected' && 'success') ||
            (actualStatus?.name === 'Disconnected' && 'warning') ||
            (actualStatus?.name === 'Failed' && 'error') ||
            'default'
          }
        >
        {actualStatus?.name ? t(`state.${actualStatus.name}`) : t('state.Undefined')}

        </Label>
      </TableCell>
      {/* <TableCell>
        <Label variant="soft">
          {store?.billingPlan?.name}
        </Label>
      </TableCell> */}
      {/* <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
      {actualStatus?.name !== 'Connected' && ( 
         <IconButton onClick={() => generateQR()}>
         <Label component="span">
           Generar QR
           <Iconify icon="solar:qr-code-bold" />
         </Label>
       </IconButton>
      )}
      </TableCell> */}

      {/* <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );

  const generateQR = () => {
    dispatch(showSpinner());
    axios
      .post(API_ENDPOINTS.instance.qr(basePath, id))
      .then(({ data }) => {
        Mixpanel.track("User Requests to Activate an Instance", {store_id: store?.id, store_name: store?.name, channel: channelName, username});
        enqueueSnackbar('QR solicitado, en unos instantes podrás escanearlo');
      })
      .catch((error) => {

        enqueueSnackbar('Se ha producido un error al solicitar el QR', { variant: 'error' });
      });
  };

  const banUser = () => {
    const PARAMS = {
      name: username,
      username,
      channel: id,
    };
    axios
      .post(API_ENDPOINTS.instance.ban, PARAMS)
      .then(({ data }) => {
        Mixpanel.track("User Deactivates an Instance", {store_id: store.id, store_name: store?.name, channel: channelName, username});

        enqueueSnackbar('La instancia fue bloqueada');
      })
      .catch((error) => {

        enqueueSnackbar('Se ha producido un error al bloquear la instancia', { variant: 'error' });
      });
  };
  return (
    <>
      {renderPrimary}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleOpenEditModal();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:settings-bold-duotone" />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            banUser();
            popover.onClose();
          }}
        >
          <Iconify icon="typcn:delete-outline" />
          Bloquear
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Eliminar
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar Instancia"
        content="Estas seguro que deseas eliminar esta instancia?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Eliminar
          </Button>
        }
      />

     {isEditModalOpen && <ChannelQuickEditInstanceForm
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        channel={channelName}
        username={username}
        channelID={id}
        basePath={basePath}
        usernameType="testUsernameType"
      />} 
    </>
  );
}
