// @mui
import { CardProps } from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
// theme
import { Checkbox, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Select, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCallback, useEffect, useRef, useState } from 'react';
import Iconify from 'src/components/iconify/iconify';
import { Box } from '@mui/system';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  action?: React.ReactNode;
  filters: any;
  onResetFilters: any;
  canReset: boolean;
  onFilters: (name: string, value: any) => void;
}

export default function StoreFilter({ action, onFilters, filters, onResetFilters,canReset, ...other }: Props) {

  const [whatsappStatus, setWhatsappStatus] = useState<boolean | null>(null);

  const [botStatus, setBotStatus] = useState<boolean | null>(null);

  const [storeName, setStoreName] = useState(filters.name);
  const timeoutIdRef = useRef<any>(undefined);
  const [countries, setCountries] = useState<any[]>([]);
  const [billingPlans, setBillingPlans] = useState<any[]>([]);

  useEffect(() => {
    axios
    .get(API_ENDPOINTS.store.countryList)
    .then(({ data }) => {
      const countryList = data.map((row: any) => {
        let countryName = '';
        switch (row.country) {
          case 'AR':
            countryName = 'Argentina';
            break;
          case 'MX':
            countryName = 'México';
            break;
          case 'BR':
            countryName = 'Brasil';
            break;
          case 'CO':
          case 'COL':
            countryName = 'Colombia';
            break;
          case 'CL':
            countryName = 'Chile';
            break;
          default:
            countryName = row.country;
        }
        return { code: row.country, name: countryName };
      });
      setCountries(countryList);
    })
    .catch((error) => {
      // Manejar error
    });
}, [])

  const renderFlag = (country: string) => {
    switch (country) {
      case 'AR':
        return <Iconify icon="twemoji:flag-argentina" />;
      case 'MX':
        return <Iconify icon="twemoji:flag-mexico" />;
      case 'BR':
        return <Iconify icon="twemoji:flag-brazil" />;
      case 'CO':
      case 'COL':
        return <Iconify icon="twemoji:flag-colombia" />;
      case 'CL':
        return <Iconify icon="twemoji:flag-chile" />;
      default:
        return <Box>{country}</Box>;
    }
  }

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      setStoreName(value);
      clearTimeout(timeoutIdRef.current);
      if (value.length >= 3){
        timeoutIdRef.current = setTimeout(() => {
          // Aquí puedes realizar tu llamada a la API con el valor
          onFilters('name', value);
        }, 500);
      }
      if (value.length === 0){
        onFilters('name', event.target.value);      
      }
    },
    [onFilters]
  );


  useEffect(() => { 
    setWhatsappStatus(filters.whatsappStatus)
    setBotStatus(filters.botStatus)
    setStoreName(filters.name)
  }, [filters])

  useEffect(() => {

    axios
    .get(API_ENDPOINTS.store.planList)
    .then(({ data }) => {
      setBillingPlans(data);
    })
    .catch((error) => {
      // Handle error
    });
  
}, [])
  


  
  const handleWhatsappStatus = useCallback(
    
    (newValue: any | null) => {
      const value = newValue.target.value;
      onFilters('whatsappStatus', value);
      
      if (value === 'connected') {
        onFilters('whatsappStatus', true);
        setWhatsappStatus(true);
      } else if (value === 'disconnected') {
        onFilters('whatsappStatus', false);
        setWhatsappStatus(false);
      } else {
        onFilters('whatsappStatus', null);
        setWhatsappStatus(null);
      }
    },
    [onFilters]
  );

  const handleBotStatus = useCallback(
    
    (newValue: any | null) => {
    
      const value = newValue.target.value;
      onFilters('botStatus', value);
      
      if (value === 'connected') {
        onFilters('botStatus', true);
        setBotStatus(true);
      } else if (value === 'disconnected') {
        onFilters('botStatus', false);
        setBotStatus(false);
      } else {
        onFilters('botStatus', null);
        setBotStatus(null);
      }
    },
    [onFilters]
  );

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
        <Stack>
          <TextField
            fullWidth            
            value={storeName}
            onChange={handleFilterName}
            placeholder="Buscar merchant..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
     


    <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>País</InputLabel>
            <Select
              value={filters.country || ''}
              onChange={(event) => onFilters('country', event.target.value)}
              input={<OutlinedInput label="País" />}
              renderValue={(selected) => {
                const selectedCountry = countries.find((country) => country.code === selected);
                return selectedCountry ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {renderFlag(selectedCountry.code)}
                    <Box sx={{ ml: 0.5 }}>{selectedCountry.name}</Box>
                  </Box>
                ) : '';
              }}
              sx={{ textTransform: 'capitalize' }}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {countries && countries.map((country) => (
                <MenuItem key={country.name} value={country.code}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.country === country.code}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {renderFlag(country.code)}
                    <Box sx={{ ml: 0.5 }}>{country.name}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>


    <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Plan de pago</InputLabel>
                <Select            
                  value={filters.billingPlan || ''}
                  onChange={(event) => onFilters('billingPlan', event.target.value)}
                  input={<OutlinedInput label="Plan de pago" />}
                  renderValue={(selected) =>                
                    billingPlans.find((item: any) => item?.plan_name === selected)?.plan_name              
                  }
                  sx={{ textTransform: 'capitalize' }}
                >
                  <MenuItem key='default-billing-plan' value=''>
                        Todos
                      </MenuItem>
                  {billingPlans.length>0 &&
                    billingPlans.map((option: any) => (
                      <MenuItem key={option.plan_name} value={option.plan_name}>
                        <Checkbox
                          disableRipple
                          size="small"
                          checked={filters.billingPlan === option.plan_name}
                        />
                        {option.plan_name}
                      </MenuItem>
                    ))}
                </Select>
        </FormControl>
        <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Modo de Operación</InputLabel>
            <Select
              value={filters.operationMode || ''}
              onChange={(event) => onFilters('operationMode', event.target.value)}
              input={<OutlinedInput label="Modo de Operación" />}
              renderValue={(selected) => selected}
              sx={{ textTransform: 'capitalize' }}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value="Automático">
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.operationMode === 'Automático'}
                />
                Automático
              </MenuItem>
              <MenuItem value="Manual">
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.operationMode === 'Manual'}
                />
                Manual
              </MenuItem>
            </Select>
          </FormControl>
        <FormControl    
          sx={{
              flexShrink: 1,
              width: { xs: 1, md: 200 },
            }}>
        <InputLabel>Estado de WhatsApp</InputLabel>
        <Select
          value={whatsappStatus === null ? '' : whatsappStatus ? 'connected' : 'disconnected'}
          onChange={handleWhatsappStatus}
        >
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="connected">Conectado</MenuItem>
          <MenuItem value="disconnected">Desconectado</MenuItem>

        </Select>
      </FormControl>
    
   
  </Stack>
  );
}
