import { formatDistanceToNowStrict } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// types
import { IChatParticipant, IChatMessage } from 'src/types/chat';
import { IConversationMessage } from 'src/types/conversation';
// components
import Iconify from 'src/components/iconify';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { enqueueSnackbar } from 'notistack';
import useHasRoles from 'src/hooks/use-has-roles';
import { AudioPlayer } from 'react-audio-play';
import { CircularProgress, Skeleton } from '@mui/material';
import Spinner from 'src/components/spinner/spinner';

//

// ----------------------------------------------------------------------

type Props = {
  message: IConversationMessage;
  participants: IChatParticipant[];
  onOpenLightbox: (value: string) => void;
  store: string;
};

export default function ConversationMessageItem({ message, participants, onOpenLightbox, store }: Props) {
  const { user } = useMockedUser();


 const [me, setme] = useState(false);
 const isAdmin = useHasRoles(['ROLE_ADMIN']);
 const { content,role, created_at, hasImage, class: classMessage, fromApp } = message;
 const [openModal, setOpenModal] = useState(false);
 const [thumbnail, setThumbnail] = useState<string | null>(null);
 const [isDownloading, setIsDownloading] = useState(false);
 const [downloadedFile, setDownloadedFile] = useState<string | null>(null);
 const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
 
  useEffect(() => {
      setme(['assistant', 'store'].includes(role))
  }, [role])
  
  const downloadBase64Data = (base64Data: any) => {


    const base64Prefix = base64Data.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/);
    let fixedBase64Data = base64Data;
    let mimeType = 'application/octet-stream'; 

    if (base64Prefix) {
      fixedBase64Data = base64Data.replace(base64Prefix[0], "");
      mimeType = base64Prefix[1]; 
    }
    const paddedBase64Data = fixedBase64Data.padEnd(fixedBase64Data.length + (4 - (fixedBase64Data.length % 4)) % 4, '=');

    const finalBase64Data = paddedBase64Data.replace(/-/g, '+').replace(/_/g, '/');
    const binaryData = atob(finalBase64Data);
    const arrayBuffer = Uint8Array.from(binaryData, char => char.charCodeAt(0)).buffer;
    const blob = new Blob([arrayBuffer]);
    
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const mime = message.mimetype.split('/');
    const MIMETYPE = mime.length > 1 ? mime[1]:mime[0];
    link.setAttribute('download', `VICI-${store}.${MIMETYPE}`); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); 
    window.URL.revokeObjectURL(url);
}

  const handleDownloadFiles = () => {
    if (message.extra_data) {
        downloadBase64Data(message.extra_data)
    }
    else {
      const URL = API_ENDPOINTS.store.pdf(store, "1", "1");
      axios
      .get(URL, { responseType: 'blob'})
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `VICI-${store}.pdf`); // or any other extension
        document.body.appendChild(link);
        link.click();

      })
      .catch((error) => {
        enqueueSnackbar('Se ha producido un error al descargar el Menú', { variant: 'error'});
      });
    }

  
  };

  const renderPreview = () => {
    if (!message.extra_data || (message.mimetype === 'pdf') || (message.mimetype === 'application/pdf')) 
    {
      return renderIcon()
    } 
    if (message.extra_data) {
      return (
        <Box
        component="img"
        alt="attachment"
        src={message.extra_data}
       // onClick={() => onOpenLightbox(content)}
        sx={{
          objectFit:'contain',
          minHeight: 220,
          borderRadius: 1.5,
          width: 400,
          maxheight: 500,
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
      />       
      );
    }
    return null;
    
  };

  const renderIcon = () => {
    const isPdf = !message.extra_data || (message.mimetype === 'pdf') || (message.mimetype === 'application/pdf')
    
  
    return (
      <>
        <Iconify 
        width={48} 
        icon={isPdf ? 'vscode-icons:file-type-pdf2' : 'material-symbols:image-sharp'} />
        
        <Typography variant="caption" component="span">{isPdf ? 'Archivo PDF' : 'Archivo'}</Typography>
      </>
      
    );
  };
  
  const renderAudio = (
    <>

      <AudioPlayer width="350px" preload="metadata"
      color="#135D66"
      sliderColor="#00A884"
      style={{ height: 'auto', backgroundColor: 'transparent', marginTop: 8 }}
      src={`data:${message.mimetype};base64,${message.extra_data}`} />
    </>

     
  )

  const handleOpenModal = async () => {
    setOpenModal(true);
    if (!downloadedFile) {
      const fileBlob = await downloadFile(message);
      if (fileBlob) {
        setDownloadedFile(fileBlob as string);
      }
    }

  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const imageModal = (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'auto', }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',  
          alignItems: 'center',  
          justifyContent: 'center',
          outline: 'none',
          width: '100%',
          height: '100%',
          overflow: 'auto',
          bgcolor: 'transparent',
          boxShadow: 24,
          p: 2
        }}
      >
         <IconButton
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8, // Ajusta según necesidad
            top: 8, // Ajusta según necesidad
            color: 'common.white', // Asegúrate de que el icono sea visible contra el fondo
            backgroundColor: 'grey.900', // Fondo para hacerlo más visible
            '&:hover': {
              backgroundColor: 'grey.800',
            },
          }}
          >
            <Iconify icon="eva:close-fill" width={24} height={24} />
          </IconButton>
          {isDownloading ? <CircularProgress /> : (
          <img
            src={downloadedFile || ''}
          style={{
            maxWidth: '90%',
            maxHeight: '90%',
            objectFit: 'contain',
            display: 'block',
          }}
            alt="Vista ampliada de la imagen"
          />
        )}
      </Box>
    </Modal>
  );

  const renderDocument = (
    <>
      <button
        type="button"
        style={{
          background: 'none',
          border: 'none',
          padding: 0,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={() => handleOpenDocument(message.extra_data)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleOpenDocument(message.extra_data);
          }
        }}
      >
        <Iconify
          icon="mdi-file-pdf-box"  // Asume que este es el identificador de un icono de PDF en Iconify
          width={50}
          height={50}
          style={{
            color: 'red',  // Puedes personalizar el color aquí si es necesario
          }}
        />
        <span style={{ marginLeft: '10px' }}>Open PDF</span>
      </button>
    </>
  );
  
  function handleOpenDocument(base64Data: any) {
    const newWindow = window.open();
    newWindow?.document.write(`
      <iframe src="data:application/pdf;base64,${base64Data}" style="width:100%; height:100%;" frameborder="0"></iframe>
    `);
  }
  

  const renderImage = () => {

    let renderDimensions = { width: "200px", height: "200px" };
    if (imageDimensions.width === imageDimensions.height) {
      // Render para imágenes cuadradas
      renderDimensions = { width: "200px", height: "200px" };
    } else if (imageDimensions.width > imageDimensions.height) {
      // Render para imágenes anchas
      renderDimensions = { width: "200px", height: "120px" };
    } else {
      // Render para imágenes altas
      renderDimensions = { width: "160px", height: "200px" };
    }
    return (
      <button
        type="button" // Explicitamente declara el tipo de botón como "button"
        style={{
          background: 'none',
          border: 'none',
          padding: 0,
          cursor: 'pointer',
          position: 'relative', // Necesario para posicionar el spinner sobre la imagen
        }}
        onClick={() => handleOpenModal()}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleOpenModal();
          }
        }}
      >
        {thumbnail ? (
          <>
            <img
              key={thumbnail + new Date().getTime()}
              width={renderDimensions.width}
              style={{
                maxHeight: renderDimensions.height,
                backgroundColor: 'transparent', // de esta manera no se ve el fondo blanco del contenedor
                width: '100%', // La imagen ocupará todo el ancho del contenedor           
                objectFit: 'contain', // Mantiene el aspecto de la imagen sin distorsionarla
              }}
              src={thumbnail || ''}
              alt="Imagen del mensaje"
            />
          </>
        ) : (
          <Skeleton variant="rectangular" height={renderDimensions.height} width={renderDimensions.width}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
              <Iconify icon="ic:baseline-photo" width={32} height={32} />
            </Box>
          </Skeleton>
        )}

      </button>

    )
  }

  const downloadThumbnail = useCallback(async (message: IConversationMessage): Promise<void> => {
    if (message.mimetype?.startsWith('image/')) {
      try {
        const { data } = await axios.get(API_ENDPOINTS.message.downloadThumbnail(message.id), {
          responseType: 'blob',
        });
        const blobUrl = URL.createObjectURL(data);
        setThumbnail(blobUrl);
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
        downloadFile(message);
        setThumbnail(null);
      }
    }
  }, [message.id, message.mimetype]);

  const downloadFile = async (message: IConversationMessage): Promise<String | null> => {
    if (message.mimetype) {
      setIsDownloading(true);
      try {
        const { data } = await axios.get(API_ENDPOINTS.message.downloadFile(message.id), {
          responseType: 'blob',
        });

        const blobUrl = URL.createObjectURL(data);
        setThumbnail(blobUrl);
        setIsDownloading(false);
        return blobUrl;
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
        setIsDownloading(false);
        return null;
      }
    }
    return null;
  };

  const renderMessageContent = useMemo(() => {
    if (message.mimetype?.startsWith('application/')) {
      return renderPDF;
    }

    if (message.mimetype?.startsWith('audio/')) {
      return message.content;
    }

    if (message.mimetype?.startsWith('image/')) {
      const img = new Image();
      img.src = downloadedFile || '';
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
      return renderImage();
    }
    return message.content;
  }, [thumbnail]);

  useEffect(() => {
    if (message.mimetype) {
      downloadThumbnail(message);
    }
  }, [message.mimetype]);

  
  const renderAuthor = (
    <Box justifyContent="flex-end" textAlign='right' pr='5' pb='0-5' display="flex" alignItems="center">
      <Typography
        fontSize='12px'
      >
        {me && (classMessage === 'message-bot' || classMessage === 'message-api') && 'Asistente '}
        {me && classMessage.includes('store') && fromApp && 'Enviado desde Whatsapp '}
        {me && classMessage.includes('store') && !fromApp && 'Enviado desde la Tienda '}
        {classMessage === 'message-bot' || classMessage === 'message-api' ? (
          <img src="/assets/ia-icon.svg" alt="ia-icon" width={18} style={{ marginLeft: 4 }} />
        ) : null}
        {classMessage.includes('store') && (
          fromApp ? <Iconify icon="ic:baseline-whatsapp" style={{ marginLeft: 4 }} /> : <Iconify icon="mdi:store" style={{ marginLeft: 4 }} />
        )}
      </Typography>
    </Box>
  );
  


  const renderInfo = (
    <Typography
      noWrap
      textAlign='end'
      variant="caption"
      sx={{
        color: 'text.disabled',
        display: 'flex',
        justifyContent: 'flex-end', 
        alignItems: 'center', 
        gap: 0.5
      }}
    >
      {new Date(created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
      {message.role === "assistant" && (
        <Iconify 
          icon="charm:tick-double" 
          width={18} 
          sx={{ color: 'text.disabled' }} 
        />
      )}
    </Typography>
  );
  
  const renderPDF = (
    <>
    <Stack direction="row" alignItems="center" spacing={1} onClick={handleDownloadFiles} sx={{ cursor: 'pointer' }}>
      {renderPreview()}

    </Stack>
    </>

)
  const renderBody = (
    <Stack
    component={message.orderId ? Link : 'div'} // Usar Link solo si hay un orderId
    to={message.orderId ? paths.dashboard.order.details(message.orderId) : undefined}
    title={message.orderId ? `Ver Pedido ${message.orderId}` : undefined}
    sx={{
      p: 0.5,
      pl: 1,
      pr: 1,
      minWidth: 48,
      maxWidth: 700,
      borderRadius: 1,
      typography: 'body2',
      bgcolor: 'background.neutral',
      ...(me && {
        color: 'grey.800',
        bgcolor: '#D9FDD3',
      }),
      textDecoration: 'none', 
      whiteSpace: 'normal',
      '&:hover': {
        textDecoration: 'none',
        fontWeight: message.orderId ? 'bold' : 'normal',
      },
      ...(hasImage && {
        p: 0,
        bgcolor: 'transparent',
      }),
    }}
  >
        {hasImage ? (
        <Box
          component="img"
          alt="attachment"
          src={content}
          onClick={() => onOpenLightbox(content)}
          sx={{
            minHeight: 220,
            borderRadius: 1.5,
            cursor: 'pointer'
          }}
        />
      ) : (
        <>
      <Box
  sx={{
    display: 'flex',
    alignItems: 'flex-start'
  }}
>


  <Box
    sx={{
      display: 'flex',
      alignItems: 'center', // Esto alinea verticalmente el contenido
      maxWidth: '100%',
      overflow: 'hidden',
      wordBreak: 'break-word'
    }}
  >
    {renderMessageContent}
    
  </Box>
</Box>

        </>
        
      )
    }    
    {renderInfo}
  </Stack>
  );

  const renderActions = (
    <Stack
      direction="row"
      className="message-actions"
      sx={{
        pt: 0.5,
        opacity: 0,
        top: '100%',
        left: 0,
        position: 'absolute',
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        ...(me && {
          left: 'unset',
          right: 0,
        }),
      }}
    >
      <IconButton size="small">
        <Iconify icon="solar:reply-bold" width={16} />
      </IconButton>
      <IconButton size="small">
        <Iconify icon="eva:smiling-face-fill" width={16} />
      </IconButton>
      <IconButton size="small">
        <Iconify icon="solar:trash-bin-trash-bold" width={16} />
      </IconButton>
    </Stack>
  );

  return (
    <>
    {openModal && imageModal }
    {role !== 'system' && (

      <Stack direction="row" justifyContent={me ? 'flex-end' : 'unset'} sx={{ mb: 0.5}}>
      
        <Stack alignItems="flex-end">          
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              position: 'relative',
              '&:hover': {
                '& .message-actions': {
                  opacity: 1,
                },
              },
            }}
          >
            <Box alignItems="flex-end" flexDirection="column">
            {me && renderAuthor}
            <Box
              alignItems="center"
            >
              {renderBody}
              {/* {renderActions} */}
            </Box>
          </Box>
          </Stack>
        </Stack>
      </Stack>

    )}
    </>
  );
}
