// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
// types
import { IJobItem } from 'src/types/job';
import { IStoreItem } from 'src/types/store';
// utils
import useLocales from 'src/locales/use-locales';
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import ChannelCarousel from '../channel/channel-carousel';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

type Props = {
  store: IStoreItem;
  onView: VoidFunction;
  onEdit: VoidFunction;
  onDisable: VoidFunction;
  onEnable: VoidFunction;
};

export default function StoreItem({ store, onView, onEdit, onEnable, onDisable }: Props) {
  const popover = usePopover();
  const { t } = useLocales();
  
  const { id, name, address, state, delivery, takeaway, store_categories, faqs, hasChannelConnected, channelCount, channelsList, plan_name, country, ia_operation_mode, url} = store;
  const [billingPlan, setBillingPlan] = useState<any>(null);
  const renderFlag = () => {
    switch (country) {
      case 'AR':
        return <Iconify icon="twemoji:flag-argentina" />;
      case 'MX':
        return <Iconify icon="twemoji:flag-mexico" />;
      case 'BR':
        return <Iconify icon="twemoji:flag-brazil" />;
      case 'CO':
      case 'COL':
        return <Iconify icon="twemoji:flag-colombia" />;
      case 'CH':
        return <Iconify icon="twemoji:flag-chile" />;
      default:
        return <Box>{country}</Box>;
    }
  }
  const getBillingPlan = useCallback(async () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const since = `${year}-${month}-01`;
    const to = `${year}-${month}-${new Date(year, currentDate.getMonth() + 1, 0).getDate()}`;

    const payload = {
      store: id,
      since: since,
      to: to,
    };

    axios
      .post(API_ENDPOINTS.billingPlan.detailsByStoreAndDate, payload)
      .then(({ data }) => {
        setBillingPlan(parseFloat(data).toFixed(2)); 
      })
      .catch((error) => {
        setBillingPlan("Hubo un error al obtener el plan de facturación");
        console.error('Error fetching billing plan:', error);
      });
  
  }, []);


  useEffect(() => {
    getBillingPlan();
  }, [getBillingPlan, id]);
  return (
    <>
      <Card>

        <Stack sx={{ p: 3, pb: 1 }}> 
         <ListItemText
            sx={{ mb: 1 }}
            primary={
                <Stack direction="row" alignItems="center" spacing={1}>
                  {renderFlag()}
                  <Typography variant="subtitle1">{name}</Typography>
             
                </Stack>
            }
            secondary={
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Iconify icon="mdi:map-marker" width={16} sx={{ color: 'info.main' }} />
                <Typography variant="caption">
                  {address ? `${address}` : "Sin dirección"}
                </Typography>
              </Stack>
            }
            primaryTypographyProps={{
              typography: 'subtitle1',
            }}
            secondaryTypographyProps={{
              mt: 1,
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Iconify icon="mdi:credit-card" width={16} sx={{ color: 'success.dark' }} />
            <Typography variant="caption" noWrap>{plan_name.charAt(0).toUpperCase() + plan_name.slice(1)}</Typography>
            <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="caption" noWrap>${billingPlan}</Typography>
          </Stack>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Iconify icon="mdi:robot" width={16} sx={{ color: 'warining.dark' }} />
            <Typography variant="caption" noWrap>{t(ia_operation_mode.name)}</Typography>
          </Stack>
          {url ? (
              <Link href={url} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center' }}>
              <Iconify icon="mdi:link" width={16} sx={{ mr: 0.5, color: 'info.dark' }} />
              <Typography variant="caption" noWrap>Ver tienda</Typography>
            </Link>
            ) : (
              <Typography variant="caption" noWrap>Sin URL</Typography>
            )
          }
        
          <ChannelCarousel channelsList={channelsList} />
          
       

      
         
        </Stack>
        
      

       
     
      </Card>

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onView();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          Detalle
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            onEdit();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>
        
        <MenuItem
          onClick={() => {
            popover.onClose();
            if (state.name === 'Dissabled'){
              onEnable();
            } else {
              onDisable();
            }
          }}
          sx={{ color: state.name === "Dissabled" ? 'success.main': 'error.main' }}
        >
          <Iconify icon={state.name === "Dissabled" ? 'fluent:presence-available-16-regular': 'vaadin:ban'} />
          {state?.name === "Dissabled" ? 'Habilitar': 'Deshabilitar'}
        </MenuItem>
      </CustomPopover> */}
    </>
  );
}
