import { useEffect, useRef, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
// types
import { IChatParticipant } from 'src/types/chat';
// components
import Lightbox, { useLightBox } from 'src/components/lightbox';
import Scrollbar from 'src/components/scrollbar';
//
import { IConversationMessage } from 'src/types/conversation';
import { TypeAnimation } from 'react-type-animation';
import ConversationMessageItem from './conversation-message-item';

import { useMessagesScroll } from '../chat/hooks';

// ----------------------------------------------------------------------

type Props = {
  messages: IConversationMessage[];
  participants: IChatParticipant[];
  loadMoreConversations: () => void;
  store: string;
  newMessage?: boolean;
  isLoading?: boolean;
};

export default function ConversationMessageList({
  messages,
  participants,
  loadMoreConversations,
  store,
  newMessage,
  isLoading,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [scrollAdjusted, setScrollAdjusted] = useState(false);
  const scrollRef = useRef<any>(null);


  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (newMessage) {
      scrollToBottom();
    }
  }, [newMessage]);

  useEffect(() => {

      scrollToBottom();

  }, [messages]);

  useEffect(() => {
    const currentContainerRef = containerRef.current;

    const handleScroll = () => {
      if (currentContainerRef && currentContainerRef.scrollTop === 0) {
        loadMoreConversations();
      }
    };

    if (currentContainerRef) {
      currentContainerRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentContainerRef) {
        currentContainerRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadMoreConversations]);

  const slides = !!messages && messages.map((message) => ({ src: message.content }));

  useEffect(() => {}, [messages]);

  const lightbox = useLightBox(slides);

  return (
    <>
      <Scrollbar ref={containerRef} sx={{ px: 2, py: 3, pb:15, mb: 20, height: '100%', width: 1, overflowY: 'auto' }}>
      
          {isLoading && (
            <TypeAnimation
              sequence={[
                'Cargando mensajes ...',
                500, // Waits 1s
                '',
                100,
                'Cargando mensajes ...',
                500, // Waits 1s
              ]}
              wrapper="span"
              // omitDeletionAnimation
              repeat={5}
              style={{ display: 'inline-block' }}
            />
          )}
          {messages.map((message) => (
            <ConversationMessageItem
              key={message.id}
              message={message}
              participants={participants}
              onOpenLightbox={() => lightbox.onOpen(message.content)}
              store={store}
            />
          ))}
       
      </Scrollbar>

      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
      />
    </>
  );
}
